<template>
  <form
    id="main_data"
    name="main_data"
    class="main_data"
    @submit.prevent
    v-if="!$apollo.loading && currentBrokerData && showForm"
    ref="mainData"
  >
    <button
      type="button"
      @keydown.prevent
      @click.prevent="needToCheckAnchor ? (checkAnchor = false) : checkError()"
      v-if="
        this.getSelectedTab.title !== 'logs' ||
        (this.getSelectedTab.title === 'logs' && this.oldData.anchor)
      "
      class="btn save_broker"
      :disabled="
        (getCurrentMode === 'review' && toReview(getBrokerData)) ||
        (getCurrentMode !== 'review' && !getIsChanged)
      "
    >
      Save
    </button>
    <div class="form__content" style="width: 100%">
      <LangTabs
        :languages="
          getCurrentMode === 'review' || getSelectedTab.title === 'logs'
            ? CompairingService.compareLangData(
                brokerReview && brokerReview.currentData
                  ? brokerReview.currentData.mainData
                  : oldData
                  ? oldData.mainData
                  : null,
                brokerReview && brokerReview.changesData
                  ? brokerReview.changesData.mainData
                  : newData
                  ? newData.mainData
                  : null
              )
            : languages
        "
        name="mainData"
        :brokerData="currentBrokerData.mainData"
        :changesData="
          changedBrokerData ? changedBrokerData.mainData : undefined
        "
      />

      <DisableBroker
        :brokerData="{ disableAll: getDisableAll(currentBrokerData.mainData) }"
      />

      <SitePreview
        v-if="currentBrokerData.anchor"
        :anchor="currentBrokerData.anchor"
        :screenshotCreated="currentBrokerData.screenshotUpdate"
        :screenshotUrl="currentBrokerData.screenshotUrl"
        :noCreateScreenshot="currentBrokerData.noCreateScreenshot"
        :changedNoCreateScreenshot="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.noCreateScreenshot
            : undefined
        "
      />
      <AddFavIcon
        v-if="
          currentBrokerData.anchor &&
          this.getSelectedTab.title !== 'logs' &&
          this.getCurrentMode !== 'review'
        "
        :iconUrl="currentBrokerData.favicon"
      />
      <LangTabs
        :languages="
          getCurrentMode === 'review' || getSelectedTab.title === 'logs'
            ? CompairingService.compareLangData(
                brokerReview && brokerReview.currentData
                  ? brokerReview.currentData.brokerCompanies
                  : oldData
                  ? oldData.brokerCompanies
                  : null,
                brokerReview && brokerReview.changesData
                  ? brokerReview.changesData.brokerCompanies
                  : newData
                  ? newData.brokerCompanies
                  : null,
                'brokerCompanies'
              )
            : languages
        "
        name="companyBlock"
        :brokerData="currentBrokerData.brokerCompanies"
        :changesData="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.brokerCompanies
            : undefined
        "
      />

      <AboutBroker
        :brokerData="{
          anchor: currentBrokerData.anchor,
          foundedYear: currentBrokerData.foundedYear,
          onlineSinceYear: currentBrokerData.onlineSinceYear,
          contactsForAdmin: currentBrokerData.contactsForAdmin,
        }"
        :brokerChanges="
          changedBrokerData
            ? {
                anchor: changedBrokerData.anchor,
                foundedYear: changedBrokerData.foundedYear,
                onlineSinceYear: changedBrokerData.onlineSinceYear,
                contactsForAdmin: changedBrokerData.contactsForAdmin,
              }
            : null
        "
        :anchorExist="anchorExist"
      />
      <CheckboxSelect
        :brokerData="currentRegulations"
        :changesBrokerData="
          getSelectedTab.title === 'logs'
            ? changedBrokerData.brokerLicenses
            : this.getCurrentMode === 'review' &&
              brokerReview &&
              brokerReview.changesData
            ? brokerReview.changesData.brokerLicenses
            : undefined
        "
        type="licenses"
        title="Regulation / license number"
      />
      <PaymentFields
        :brokerData="{
          allowedCountries,
          prohibitedCountries,
          paymentOptions: currentBrokerData.paymentOptions,
          currencyPairs: currentBrokerData.currencyPairs,
          acceptsUsClients: currentBrokerData.acceptsUsClients,
          accountCurrencies: currentBrokerData.accountCurrencies,
        }"
        :brokerChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? {
                allowedCountries: changedAllowedCountries,
                prohibitedCountries: changedProhibitedCountries,
                paymentOptions: changedBrokerData.paymentOptions,
                currencyPairs: changedBrokerData.currencyPairs,
                acceptsUsClients: changedBrokerData.acceptsUsClients,
                accountCurrencies: changedBrokerData.accountCurrencies,
              }
            : undefined
        "
      />
      <BrokerRelations
        name="ibBroker"
        label="Is this broker IB of same other"
        desc="Either select one from the list or, if it's absent, input the name into the field"
        :selected="currentBrokerData.ibBroker"
        :changesSelected="
          (this.getCurrentMode === 'review' &&
            brokerReview &&
            brokerReview.changesData) ||
          getSelectedTab.title === 'logs'
            ? changedBrokerData.ibBroker
            : undefined
        "
        v-if="
          $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
            ? CompairingService.showField(
                currentBrokerData.ibBroker,
                changedBrokerData ? changedBrokerData.ibBroker : null
              )
            : true
        "
      />
      <BrokerOptions
        :brokerData="currentBrokerOptions"
        :brokerChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.brokerOptions
            : undefined
        "
      />
      <DemoPlatformsInput
        :brokerData="currentBrokerData.brokerDemoPlatforms"
        :changesBrokerData="
          changedBrokerData ? changedBrokerData.brokerDemoPlatforms : undefined
        "
      />
      <BrokerWebsite
        :brokerData="{
          websiteLanguages: currentBrokerData.websiteLanguages,
          supportLanguages: currentBrokerData.supportLanguages,
          freeEducation: currentBrokerData.freeEducation,
          vpsState: currentBrokerData.vpsState,
          vpsValue: currentBrokerData.vpsValue,
          serverTimezone: currentBrokerData.serverTimezone,
          stopOrdersRebates: currentBrokerData.stopOrdersRebates || '0',
          limitOrdersRebates: currentBrokerData.limitOrdersRebates || '0',
          allOrdersRebates: currentBrokerData.allOrdersRebates || '0',
          supportMethods: currentBrokerData.supportMethods,
        }"
        :brokerChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? {
                countryOffices: changedBrokerData.countryOffices,
                websiteLanguages: changedBrokerData.websiteLanguages,
                supportLanguages: changedBrokerData.supportLanguages,
                freeEducation: changedBrokerData.freeEducation,
                vpsState: changedBrokerData.vpsState,
                vpsValue: changedBrokerData.vpsValue,
                serverTimezone: changedBrokerData.serverTimezone,
                stopOrdersRebates: changedBrokerData.stopOrdersRebates,
                limitOrdersRebates: changedBrokerData.limitOrdersRebates,
                allOrdersRebates: changedBrokerData.allOrdersRebates,
                supportMethods: changedBrokerData.supportMethods,
              }
            : null
        "
      />
      <LangTabs
        :languages="
          getCurrentMode === 'review' || getSelectedTab.title === 'logs'
            ? CompairingService.compareLangData(
                brokerReview && brokerReview.currentData
                  ? brokerReview.currentData.brokerVipConditions
                  : oldData
                  ? oldData.brokerVipConditions
                  : null,
                brokerReview && brokerReview.changesData
                  ? brokerReview.changesData.brokerVipConditions
                  : newData
                  ? newData.brokerVipConditions
                  : null
              )
            : languages
        "
        name="vipCondition"
        :brokerData="currentBrokerData.brokerVipConditions"
        :changesData="
          (this.getCurrentMode === 'review' ||
            this.getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.brokerVipConditions
            : undefined
        "
      />
      <LangTabs
        v-if="isAdmin"
        :languages="
          getCurrentMode === 'review' || getSelectedTab.title === 'logs'
            ? CompairingService.compareLangData(
                brokerReview && brokerReview.currentData
                  ? brokerReview.currentData.brokerNotes
                  : oldData && oldData.brokerNotes && oldData.brokerNotes.length
                  ? oldData.brokerNotes
                  : null,
                brokerReview && brokerReview.changesData
                  ? brokerReview.changesData.brokerNotes
                  : newData && newData.brokerNotes && newData.brokerNotes.length
                  ? newData.brokerNotes
                  : null
              )
            : languages
        "
        name="brokerNotes"
        :brokerData="currentBrokerData.brokerNotes"
        :changesData="
          (this.getCurrentMode === 'review' ||
            this.getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.brokerNotes
            : undefined
        "
      />
      <LangTabs
        v-if="isAdmin"
        :languages="
          getCurrentMode === 'review' || getSelectedTab.title === 'logs'
            ? CompairingService.compareLangData(
                brokerReview && brokerReview.currentData
                  ? brokerReview.currentData.brokerWarningSings
                  : oldData &&
                    oldData.brokerWarningSings &&
                    oldData.brokerWarningSings.length
                  ? oldData.brokerWarningSings
                  : null,
                brokerReview && brokerReview.changesData
                  ? brokerReview.changesData.brokerWarningSings
                  : newData &&
                    newData.brokerWarningSings &&
                    newData.brokerWarningSings.length
                  ? newData.brokerWarningSings
                  : null
              )
            : languages
        "
        name="brokerWarningSings"
        :brokerData="currentBrokerData.brokerWarningSings"
        :changesData="
          (this.getCurrentMode === 'review' ||
            this.getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.brokerWarningSings
            : undefined
        "
      />
      <BrokerVipOptions
        :brokerData="currentBrokerVipOptions"
        :brokerChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.brokerVipConditionOptions
            : undefined
        "
      />
      <BrokerRelations
        name="ebBroker"
        label="Was this broker acquired by some other"
        desc="Either select one from the list or, if it's absent, input the name into the field"
        :selected="currentBrokerData.ebBroker"
        :changesSelected="
          (this.getCurrentMode === 'review' &&
            brokerReview &&
            brokerReview.changesData) ||
          getSelectedTab.title === 'logs'
            ? changedBrokerData.ebBroker
            : undefined
        "
        v-if="
          ($route.name === 'brokerReview' || getSelectedTab.title === 'logs'
            ? CompairingService.showField(
                currentBrokerData.ebBroker,
                changedBrokerData ? changedBrokerData.ebBroker : null
              )
            : true) && this.getCurrentMode !== 'create'
        "
      />
      <BrokerFlags
        :isAdmin="isAdmin"
        :brokerData="{
          autochartist: currentBrokerData.autochartist,
          bully: currentBrokerData.bully,
          scam: currentBrokerData.scam,
        }"
        :brokerChanges="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? {
                autochartist: changedBrokerData.autochartist,
                bully: changedBrokerData.bully,
                scam: changedBrokerData.scam,
              }
            : undefined
        "
      />
      <LangTabs
        :languages="
          getCurrentMode === 'review' || getSelectedTab.title === 'logs'
            ? CompairingService.compareLangData(
                brokerReview && brokerReview.currentData
                  ? brokerReview.currentData.brokerFlags
                  : oldData
                  ? oldData.brokerFlags
                  : null,
                brokerReview && brokerReview.changesData
                  ? brokerReview.changesData.brokerFlags
                  : newData
                  ? newData.brokerFlags
                  : null
              )
            : languages
        "
        name="brokerFlags"
        :brokerData="currentBrokerData.brokerFlags"
        :changesData="
          (this.getCurrentMode === 'review' ||
            this.getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.brokerFlags
            : undefined
        "
      />
      <LangTabs
        :languages="
          getCurrentMode === 'review' || getSelectedTab.title === 'logs'
            ? CompairingService.compareLangData(
                brokerReview && brokerReview.currentData
                  ? brokerReview.currentData.brokerHistory
                  : oldData
                  ? oldData.brokerHistory
                  : null,
                brokerReview && brokerReview.changesData
                  ? brokerReview.changesData.brokerHistory
                  : newData
                  ? newData.brokerHistory
                  : null,
                'brokerHistory'
              )
            : languages
        "
        name="history"
        :brokerData="currentBrokerData.brokerHistory"
        :changesData="
          (this.getCurrentMode === 'review' ||
            getSelectedTab.title === 'logs') &&
          changedBrokerData
            ? changedBrokerData.brokerHistory
            : undefined
        "
      />
    </div>
    <MassToggler v-if="getCurrentMode === 'review'" />
    <button
      type="button"
      @keydown.prevent
      @click.prevent="needToCheckAnchor ? (checkAnchor = false) : checkError()"
      v-if="
        this.getSelectedTab.title !== 'logs' ||
        (this.getSelectedTab.title === 'logs' && this.oldData.anchor)
      "
      class="btn btn--big"
      :disabled="
        (getCurrentMode === 'review' && toReview(getBrokerData)) ||
        (getCurrentMode !== 'review' && !getIsChanged)
      "
    >
      {{
        this.getSelectedTab.title === "logs"
          ? "Rollback"
          : this.getCurrentMode === "review"
          ? "Review"
          : "Save"
      }}
      broker
    </button>
    <Modal title="Save data?" id="unsavedData">
      <template :slot="'modal-content'">
        <center>All unsaved data will be lost!</center>
      </template>
      <template slot="modal-controls">
        <button
          class="btn"
          @click.prevent="
            needToCheckAnchor ? (checkAnchor = false) : checkError(),
              closeModal()
          "
          :disabled="getCurrentMode === 'review' && toReview(getBrokerData)"
        >
          Save and exit
        </button>
        <button type="button" @click="changePage" class="btn btn--error">
          Exit without saving
        </button>
      </template>
    </Modal>
  </form>
</template>

<script>
import {
  isBrokerAnchorBusy,
  languages,
  options,
  vipConditionOptions,
  countryOffices,
} from "@/components/graphQL/brokerQueries";
import {
  createBroker,
  editBroker,
  reviewBroker,
  rollbackBrokerLog,
} from "../graphQL/brokerMutations";
import brokerReview from "@/components/graphQL/fragments/brokerMainDataReview.gql";
import broker from "@/components/graphQL/fragments/brokerMainData.gql";
import AboutBroker from "@/components/formParts/AboutBroker";
import LangTabs from "@/components/formParts/LangTabs";
import DisableBroker from "@/components/formParts/DisableBroker";
import SitePreview from "@/components/formParts/SitePreview";
import CheckboxSelect from "@/components/formParts/CheckboxSelect";
import PaymentFields from "@/components/formParts/PaymentFields";
import BrokerOptions from "@/components/formParts/BrokerOptions";
import DemoPlatformsInput from "@/components/formFields/DemoPlatforms";
import BrokerWebsite from "@/components/formParts/BrokerWebsite";
import BrokerVipOptions from "@/components/formParts/BrokerVipOptions";
import BrokerRelations from "@/components/formParts/BrokerRelations";
import CompairingService from "@/services/CompairingService";
import GetReviewState from "@/services/GetReviewState";
import MassToggler from "@/components/formParts/MassToggler";

import ParseServicesClass from "@/services/ParseServices";
import { mapGetters, mapMutations } from "vuex";
import Modal from "@/components/Modal";
import generalReview from "@/components/graphQL/fragments/generalReview.gql";
import BrokerFlags from "@/components/formParts/BrokerFlags";
import AddFavIcon from "@/components/formParts/AddFavIcon.vue";

let ParseServices = new ParseServicesClass();

export default {
  name: "BrokerForm",
  components: {
    BrokerFlags,
    BrokerRelations,
    DemoPlatformsInput,
    BrokerOptions,
    PaymentFields,
    AboutBroker,
    LangTabs,
    DisableBroker,
    SitePreview,
    CheckboxSelect,
    BrokerWebsite,
    BrokerVipOptions,
    MassToggler,
    Modal,
    AddFavIcon,
  },
  props: {
    oldData: Object,
    newData: Object,
    currentData: Object,
  },
  data() {
    return {
      CompairingService: new CompairingService(),
      GetReviewState: new GetReviewState(),
      checkAnchor: true,
      anchorExist: "",
      showForm: true,
      updatedOldData: false,
      existedAnchor: "",
      needToCheckAnchor: false,
    };
  },
  apollo: {
    broker: {
      query: broker,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return (
          this.$route.name !== "brokerEdit" ||
          this.getSelectedTab.title === "logs"
        );
      },
      result() {
        // if (this.isAdmin) {
        // set current brokers in store
        this.setCurrentBrokerMainData({
          id: this.$route.params.id,
          name: this.broker.name,
          reviewed: this.broker.reviewed,
        });
        // set related brokers in store
        this.setRelatedBrokers(this.broker.relatedBrokers);
        // set parent broker name and id in store
        this.setParentBrokerMainData({
          name: this.broker.parentBrokerName,
          id: this.broker.parentBrokerId,
        });
        // }

        this.setDataToStore();
      },
    },
    brokerReview: {
      query: brokerReview,

      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return (
          this.$route.name !== "brokerReview" ||
          this.getSelectedTab.title === "logs"
        );
      },
      result() {
        this.setDataToStore();
      },
    },
    languages: languages,
    options: options,
    vipConditionOptions: vipConditionOptions,
    isBrokerAnchorBusy: {
      query: isBrokerAnchorBusy,
      variables() {
        return {
          anchor: document.querySelector("input[name='anchor']").value,
          brokerId:
            this.getCurrentMode !== "create" ? this.$route.params.id : null,
        };
      },
      skip() {
        return this.checkAnchor;
      },
      result({ data }) {
        this.checkAnchor = true;
        this.anchorExist = data.isBrokerAnchorBusy
          ? document.querySelector("input[name='anchor']").value
          : "";
        this.checkError();
      },
    },
    generalReview: {
      query: generalReview,
      variables() {
        return {
          brokerId: this.$route.params.id,
        };
      },
      skip() {
        return this.$route.name !== "brokerReview";
      },
      result() {
        this.setDataToStore();
      },
    },
    countryOffices,
  },
  computed: {
    ...mapGetters([
      "getBrokerData",
      "getCurrentMode",
      "getDefaultData",
      "getLinkToGo",
      "getIsChanged",
      "getSelectedTab",
    ]),
    currentBrokerData: (vm) => {
      if (vm.getSelectedTab.title === "logs") {
        return vm.oldData;
      } else {
        switch (vm.getCurrentMode) {
          case "edit":
            if (vm.broker) {
              return vm.broker;
            } else if (vm.oldData && vm.oldData.anchor) {
              return vm.oldData;
            } else if (vm.oldData && !vm.oldData.anchor) {
              return vm.getDefaultData;
            }
            break;
          case "review":
            if (vm.brokerReview) {
              return vm.brokerReview.currentData;
            }
            break;
          default:
            return vm.getDefaultData;
        }
      }
    },
    changedBrokerData: (vm) => {
      if (vm.newData) {
        return vm.newData;
      } else if (vm.brokerReview && vm.brokerReview.changesData) {
        return vm.brokerReview.changesData;
      } else {
        return undefined;
      }
    },
    currentBrokerOptions: (vm) => {
      if (vm.getSelectedTab.title === "logs") {
        return vm.oldData.brokerOptions
          ? vm.CompairingService.compareRadioOptions(
              vm.oldData.brokerOptions && vm.oldData.brokerOptions.length
                ? vm.oldData.brokerOptions
                : null,
              vm.newData.brokerOptions && vm.newData.brokerOptions.length
                ? vm.newData.brokerOptions
                : null
            )
          : vm.newData.brokerOptions.map((item) => {
              return (item = { ...item, optionState: "NO" });
            });
      } else {
        switch (vm.getCurrentMode) {
          case "edit":
            if (vm.broker) {
              return vm.broker.brokerOptions;
            }
            break;
          case "review":
            if (vm.brokerReview && vm.brokerReview.changesData) {
              return vm.CompairingService.compareRadioOptions(
                vm.brokerReview.currentData.brokerOptions,
                vm.brokerReview.changesData.brokerOptions
              );
            } else {
              return vm.brokerReview.currentData.brokerOptions;
            }
          default:
            return vm.options;
        }
      }
    },
    currentBrokerVipOptions: (vm) => {
      if (vm.getSelectedTab.title === "logs") {
        return vm.oldData.brokerVipConditionOptions
          ? vm.CompairingService.compareRadioOptions(
              vm.oldData.brokerVipConditionOptions &&
                vm.oldData.brokerVipConditionOptions.length
                ? vm.oldData.brokerVipConditionOptions
                : null,
              vm.newData.brokerVipConditionOptions &&
                vm.newData.brokerVipConditionOptions.length
                ? vm.newData.brokerVipConditionOptions
                : null
            )
          : [];
      } else {
        switch (vm.getCurrentMode) {
          case "edit":
            if (vm.broker) {
              return vm.broker.brokerVipConditionOptions;
            }
            break;
          case "review":
            if (vm.brokerReview && vm.brokerReview.changesData) {
              return vm.CompairingService.compareRadioOptions(
                vm.brokerReview.currentData.brokerVipConditionOptions,
                vm.brokerReview.changesData.brokerVipConditionOptions
              );
            } else {
              return vm.brokerReview.currentData.brokerVipConditionOptions;
            }
          default:
            return vm.vipConditionOptions;
        }
      }
    },
    isAdmin() {
      return localStorage.getItem("Mode") === "manager";
    },
    allowedCountries() {
      let arr = [];
      if (this.currentBrokerData?.brokerCountryOffices && this.countryOffices) {
        let data = this.currentBrokerData.brokerCountryOffices
          .filter((item) => item.status === "ALLOWED")
          .map((item) => item.countryOfficeId);
        if (data.length) {
          data.forEach((id) => {
            arr.push(this.countryOffices.find((item) => item.id === id));
          });
        }
      }
      return arr;
    },
    prohibitedCountries() {
      let arr = [];
      if (this.currentBrokerData?.brokerCountryOffices && this.countryOffices) {
        let data = this.currentBrokerData.brokerCountryOffices
          .filter((item) => item.status === "PROHIBITED")
          .map((item) => item.countryOfficeId);
        if (data.length) {
          data.forEach((id) => {
            arr.push(this.countryOffices.find((item) => item.id === id));
          });
        }
      }
      return arr;
    },
    changedAllowedCountries() {
      let arr = [];
      if (this.changedBrokerData?.brokerCountryOffices && this.countryOffices) {
        let data = this.changedBrokerData.brokerCountryOffices
          .filter((item) => item.status === "ALLOWED")
          .map((item) => item.countryOfficeId);
        if (data.length) {
          data.forEach((id) => {
            arr.push(this.countryOffices.find((item) => item.id === id));
          });
        }
      }
      return arr;
    },
    changedProhibitedCountries() {
      let arr = [];
      if (this.changedBrokerData?.brokerCountryOffices && this.countryOffices) {
        let data = this.changedBrokerData.brokerCountryOffices
          .filter((item) => item.status === "PROHIBITED")
          .map((item) => item.countryOfficeId);
        if (data.length) {
          data.forEach((id) => {
            arr.push(this.countryOffices.find((item) => item.id === id));
          });
        }
      }
      return arr;
    },
    currentRegulations() {
      return this.currentBrokerData?.brokerLicenses?.length
        ? this.currentBrokerData.brokerLicenses
        : this.getBrokerData.brokerLicenses;
    },
  },
  methods: {
    ...mapMutations([
      "updateMainData",
      "updateBrokerLicenses",
      "updateVipConditions",
      "updateBrokerOptions",
      "updateVipOptions",
      "updateBrokerInfo",
      "setCurrentMode",
      "updateDemoPlataformItem",
      "setHistoryItem",
      "resetingBroker",
      "removingEmptyDemoPlatforms",
      "removingTypeNames",
      "preparingDataForReview",
      "cleaningHistory",
      "editBrokerHistory",
      "settingReviewField",
      "setingOldBrokerData",
      "settingDatatoReviewed",
      "isFormChangedDetect",
      "setSelectedTab",
      "setNotification",
      "changeTabsNeedUpdates",
      "clearDisabledLanguages",
      "removeCreatedAt",
      "removeUpdatedAt",
      "updateBrokerWarningSings",
      "updateBrokerNotes",
      "setBrokerCompany",
      "removingEmptyBrokerCompanies",
      "preparingBrokerCountries",
      "updateCustomFlags",
      "removeFavicon",
    ]),
    ...mapMutations({
      setCurrentBrokerMainData: "SET_CURRENT_BROKER_MAIN_DATA",
    }),
    ...mapMutations({ setParentBrokerMainData: "SET_PARENT_BROKER_MAIN_DATA" }),
    ...mapMutations({ setRelatedBrokers: "SET_RELATED_BROKERS" }),
    closeModal() {
      document.querySelector("#unsavedData").classList.remove("modal--open");
    },
    sendForm: function () {
      this.showForm = false;
      if (this.getSelectedTab.title === "logs") {
        ParseServices.parseRollbackField().forEach((item) => {
          this.settingDatatoReviewed(item);
        });

        this.preparingBrokerCountries();
      } else if (this.getCurrentMode === "review") {
        ParseServices.parseReviewedField().forEach((item) => {
          this.settingDatatoReviewed(item);
        });
      } else {
        ParseServices.parseMainData().forEach((item) => {
          this.updateMainData(item);
        });
        this.updateBrokerLicenses(ParseServices.parseBrokerLicenses());
        ParseServices.parseVipConditions().forEach((item) => {
          this.updateVipConditions(item);
        });
        this.updateBrokerOptions(ParseServices.parseBrokerOptions());
        this.updateVipOptions(ParseServices.parseVipOptions());
        ParseServices.parseSimpleInputs().forEach((item) => {
          this.updateBrokerInfo(item);
        });
        ParseServices.parseDemoPlatforms().forEach((item) => {
          this.updateDemoPlataformItem(item);
        });
        ParseServices.parseBrokerHistory().forEach((item) => {
          this.setHistoryItem(item);
        });
        ParseServices.parseBrokerNotes().forEach((item) => {
          this.updateBrokerNotes(item);
        });
        ParseServices.brokerWarningSings().forEach((item) => {
          this.updateBrokerWarningSings(item);
        });
        ParseServices.parseCustomFlags().forEach((item) => {
          this.updateCustomFlags(item);
        });
        ParseServices.parseBrokerCompanies().forEach((item) => {
          this.setBrokerCompany(item);
        });
      }

      this.cleaningHistory();

      this.settingReviewField(
        this.getCurrentMode !== "review"
          ? "NONE"
          : this.isProdBroker()
          ? "DONE"
          : this.GetReviewState.reviewedState()
      );
      this.removingEmptyBrokerCompanies();
      this.removingEmptyDemoPlatforms();
      this.preparingDataForReview();

      if (this.getSelectedTab.title === "logs") {
        this.removeCreatedAt();
        this.removeUpdatedAt();
      }

      // remove data before send
      delete this.getBrokerData.parentBrokerName;
      delete this.getBrokerData.parentBrokerId;
      delete this.getBrokerData.relatedBrokers;
      delete this.getBrokerData.name;
      delete this.getBrokerData.screenshotUpdate;
      delete this.getBrokerData.screenshotUrl;
      delete this.getBrokerData.allowedCountries;
      delete this.getBrokerData.prohibitedCountries;
      delete this.getBrokerData.favicon;

      let cloneBrokerData = Object.assign({}, this.getBrokerData);

      this.$apollo
        .mutate({
          mutation:
            this.getSelectedTab.title === "logs"
              ? rollbackBrokerLog
              : this.getCurrentMode === "create"
              ? createBroker
              : this.getCurrentMode === "edit"
              ? editBroker
              : this.getCurrentMode === "review"
              ? reviewBroker
              : "",
          variables: {
            BrokerData: cloneBrokerData,
            parentBrokerId: parseInt(this.$route.query.broker),
            id: this.$route.params.id,
            brokerId: this.$route.params.id,
            inputData: cloneBrokerData,
            brokerLogId: this.getSelectedTab.id,
          },
        })
        .then((res) => {
          this.setNotification({ text: "Broker saved.", type: "success" });
          this.isFormChangedDetect(false);
          this.getCurrentMode === "review"
            ? this.changeTabsNeedUpdates(true)
            : null;

          this.changePage(
            res.data.createBroker ? res.data.createBroker.id : null
          );
          this.resetingBroker();
        })
        .catch((err) => {
          console.error(err);
          if (document.querySelector(".checkbox__input")) {
            let inputs = document.querySelectorAll(".checkbox__input");
            inputs.forEach((item) => {
              if (item.value === "") {
                scrollTo({
                  top:
                    item.getBoundingClientRect().top + window.pageYOffset - 50,
                  behavior: "smooth",
                });
              }
            });
          }
          this.setNotification({
            text: "Broker not saved. An error has occurred ",
            type: "error",
          });
          this.resetingBroker();
          this.showForm = true;
        });
    },
    checkError: function () {
      this.prepareUrl();
      this.addErrorToInvalidFields();
      let error = document.querySelector(".--error");

      if (error && error.closest(".translation_info__content")) {
        let lang = Array.from(
          error.closest(".translation_info__content").elements
        ).find((item) => item.name === "languageSlug").value;
        this.setNotification({
          text:
            "Broker not saved. An error has occurred in " + lang + " language",
          type: "error",
        });
        scrollTo({
          top:
            error.closest(".translation_info").getBoundingClientRect().top +
            window.pageYOffset -
            50,
          behavior: "smooth",
        });
      } else if (error) {
        scrollTo({
          top: error.getBoundingClientRect().top + window.pageYOffset - 50,
          behavior: "smooth",
        });
      } else if (this.anchorExist !== "") {
        scrollTo({
          top:
            document
              .querySelector("input[name='anchor']")
              .getBoundingClientRect().top +
            window.pageYOffset -
            50,
          behavior: "smooth",
        });
      } else {
        this.sendForm();
      }
    },
    prepareUrl() {
      document.querySelector("#main_data").elements.forEach((input) => {
        if (
          input.type === "url" &&
          input.value !== "" &&
          (!input.value.startsWith("https://") ||
            !input.value.startsWith("https://"))
        ) {
          input.value = "https://" + input.value;
        }
      });
    },
    addErrorToInvalidFields() {
      document.querySelector("#main_data").elements.forEach((input) => {
        // check if url correct
        let isValidUrl = (urlString) => {
          let urlPattern = new RegExp(
            "^(https?:\\/\\/)?" + // validate protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // validate fragment locator
          return !!urlPattern.test(urlString);
        };

        this.getCurrentMode !== "review" && input.closest(".input__container")
          ? !input.validity.valid ||
            (input.type === "url" &&
              input.value !== "" &&
              !isValidUrl(input.value) &&
              !input.value.includes("."))
            ? input.closest(".input__container").classList.add("--error")
            : input.closest(".input__container").classList.remove("--error")
          : null;
      });

      this.checkDublicatedDemoPlatforms();

      document.querySelector(".demo_platform__main.--error")
        ? alert("Duplicated demo platforms is not allowed")
        : null;

      this.checkMainDataLink();
    },
    checkDublicatedDemoPlatforms() {
      if (document.querySelector(".demo_platform__container")) {
        let seletedDemoPlatforms = Array.from(
          document.querySelectorAll('input[name="selectedPlatform"]')
        ).map((item) => item.value);
        seletedDemoPlatforms.forEach((platform) => {
          if (
            seletedDemoPlatforms.filter((item) => item === platform).length > 1
          ) {
            Array.from(
              document.querySelectorAll(
                `input[name="selectedPlatform"][value="${platform}"]`
              )
            ).forEach((duplicate) => {
              duplicate
                .closest(".demo_platform__main")
                .classList.add("--error");
            });
          } else {
            Array.from(
              document.querySelectorAll(
                `input[name="selectedPlatform"][value="${platform}"]`
              )
            ).forEach((uniq) => {
              uniq.closest(".demo_platform__main").classList.remove("--error");
            });
          }
        });
      }
    },
    checkMainDataLink() {
      let links = [];
      document.querySelector("#main_data").elements.forEach((input) => {
        if (input.name === "link") {
          links.push(input);
        }
      });
      let filledLink = links.filter((input) => input.value !== "");

      if (filledLink.length <= 0) {
        links.forEach((input) => {
          input.closest(".input__container").classList.add("--error");
        });
      } else {
        links.forEach((input) => {
          input.closest(".input__container").classList.remove("--error");
        });
      }
    },
    changePage(id) {
      if (
        this.getCurrentMode === "review" &&
        this.generalReview.accountTypes &&
        this.generalReview.accountTypes.length &&
        this.getBrokerData.reviewed === "DONE"
      ) {
        this.setSelectedTab({ title: "account types" });
      } else if (this.getLinkToGo && this.getLinkToGo.type === "page") {
        if (parseInt(this.$route.query.broker) && window.history.length > 1) {
          this.$router.go(-1);
        } else if (this.getLinkToGo) {
          this.$router.push({ name: this.getLinkToGo.link });
        } else {
          this.$router.push({ name: "brokers" });
        }
      } else if (this.getLinkToGo && this.getLinkToGo.type === "tab") {
        this.getLinkToGo
          ? this.setSelectedTab(this.getLinkToGo.link)
          : this.$router.push({ name: "brokers" });
        this.isFormChangedDetect(false);
      } else if (this.getSelectedTab.title === "logs") {
        this.setSelectedTab({
          title: "logs",
        });
      } else {
        id
          ? this.$router.push({
              name: "brokerEdit",
              params: { id: id, tab: "account types" },
            })
          : this.$router.push({ name: "brokers" });
      }
    },
    checkDisabledBroker(target) {
      let linkInput = target.closest(".translation_info__content").elements
        .link;
      target.value
        ? linkInput.setAttribute("requered", true)
        : linkInput.removeAttribute("requered");
    },
    setDataToStore() {
      if (this.currentData) {
        this.setingOldBrokerData(this.currentData);
      } else if (
        !this.getBrokerData.anchor &&
        this.broker &&
        this.getCurrentMode !== "create" &&
        !this.updatedOldData
      ) {
        this.setingOldBrokerData(this.broker);
      } else if (
        !this.getBrokerData.anchor &&
        this.brokerReview &&
        this.getCurrentMode !== "create" &&
        !this.updatedOldData
      ) {
        this.setingOldBrokerData(this.brokerReview.currentData);
      } else if (
        !this.updatedOldData &&
        this.oldData &&
        this.getBrokerData.anchor
      ) {
        this.setingOldBrokerData(this.oldData);
      } else if (
        !this.updatedOldData &&
        this.oldData &&
        !this.getBrokerData.anchor
      ) {
        this.setingOldBrokerData(this.newData);
      }
    },
    toReview(item) {
      return (
        item.reviewed.toLowerCase() === "none" ||
        item.reviewed.toLowerCase() === "wait_after_update"
      );
    },
    getDisableAll(mainData) {
      if (this.broker === undefined) {
        return false;
      }

      let disableAll = mainData !== undefined;
      mainData?.forEach((md) => {
        if (!md.disabled) {
          disableAll = false;
        }
      });

      return disableAll;
    },
    isProdBroker() {
      return this.getBrokerData.reviewed.toLowerCase() !== "none";
    },
  },
  mounted() {
    this.resetingBroker();
    this.clearDisabledLanguages();
    this.setCurrentMode(
      this.$route.name === "brokerCreate"
        ? "create"
        : this.$route.name === "brokerEdit"
        ? "edit"
        : this.$route.name === "brokerReview"
        ? "review"
        : null
    );
    this.componentKey++;
    this.changeTabsNeedUpdates(false);
    if (this.getCurrentMode !== "create") {
      this.setDataToStore();
    }
    this.needToCheckAnchor = document.querySelector("#anchor");
  },
  watch: {
    "$route.name": function () {
      this.resetingBroker();
      this.setCurrentMode(
        this.$route.name === "brokerCreate"
          ? "create"
          : this.$route.name === "brokerEdit"
          ? "edit"
          : this.$route.name === "brokerReview"
          ? "review"
          : null
      );

      if (this.getCurrentMode !== "review" && this.brokerReview) {
        this.$apollo.queries.brokerReview.refresh();
      }
      if (this.getCurrentMode !== "edit" && this.broker) {
        this.$apollo.queries.broker.refresh();
      }
    },
  },
};
</script>
