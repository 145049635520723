<template>
  <div class="mass_togglers__container">
    <div class="mass_togglers_wrap">
      <button
        class="mass_toggler"
        type="button"
        @click="MassToggle.massReview('unset')"
      >
        <Checker horizontal disabled state="unset" />
        Leave as is
      </button>
      <button
        class="mass_toggler"
        type="button"
        @click="MassToggle.massReview('false')"
        v-if="!getSelectedTab.innerTab"
      >
        <Checker horizontal disabled state="false" />
        Cancel All
      </button>
      <button
        class="mass_toggler"
        type="button"
        @click="MassToggle.massReview('true')"
      >
        <Checker horizontal disabled state="true" />
        Approve all
      </button>
    </div>
    <div class="mass_canceled">
      <h6 class="mass_canceled__title">Сause of cancellation</h6>
      <textarea name="cancel_reasone" class="mass_canceled__input"></textarea>
    </div>
  </div>
</template>
<script>
import MassToggle from "@/services/MassToggle";
import Checker from "@/components/formFields/Checker";
import { mapGetters } from "vuex";
export default {
  name: "MassToggler",
  components: {
    Checker,
  },
  data() {
    return {
      MassToggle: new MassToggle(),
    };
  },
  computed: {
    ...mapGetters(["getSelectedTab"]),
  },
};
</script>
